<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "orderForm",
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
