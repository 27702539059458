import Vue from "vue";
import VueRouter from "vue-router";
// 首页
import HomeView from "../views/HomeView.vue";
import Home from "../views/relation/Home.vue";
// 产品
import product from "../views/product/product.vue";
import productlist from "../views/product/productlist.vue";
import productclassify from "../views/product/productclassify.vue";
//项目案例
import orderform from "../views/orderform/orderform.vue";
import orderformlist from "../views/orderform/orderformlist.vue";
import orderformclassify from "../views/orderform/orderformclassify.vue";
import orderformaudit from "../views/orderform/orderformaudit.vue";
// 关于
import advertising from "../views/advertising/advertising.vue";
import advertisinglist from "../views/advertising/advertisinglist.vue";
import advertisfrom from "../views/advertising/advertisfrom.vue";
//联系
import relation from "../views/relation/relation.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    children: [
      //产品
      {
        path: "/product",
        name: "product",
        component: product,
        children: [
          {
            path: "/productlist",
            name: "productlist",
            component: productlist,
            meta: {
              title: "多媒体电子沙盘",
            },
          },
          {
            path: "/productclassify",
            name: "productclassify",
            component: productclassify,
            meta: {
              title: "三维虚拟数字地球",
            },
          },
        ],
      },
      // 项目案例
      {
        path: "/orderform",
        name: "orderform",
        component: orderform,
        children: [
          {
            path: "/orderformlist",
            name: "orderformlist",
            component: orderformlist,
            meta: {
              title: "军队武警",
            },
          },
          {
            path: "/orderformclassify",
            name: "orderformclassify",
            component: orderformclassify,
            meta: {
              title: "国动人防",
            },
          },
          {
            path: "/orderformaudit",
            name: "orderformaudit",
            component: orderformaudit,
            meta: {
              title: "其他案例",
            },
          },
        ],
      },
      // 联系
      {
        path: "/advertising",
        name: "advertising",
        component: advertising,
        children: [
          {
            path: "/advertisinglist",
            name: "advertisinglist",
            component: advertisinglist,
            meta: {
              title: "公司简介",
            },
          },
          {
            path: "/advertisfrom",
            name: "advertisfrom",
            component: advertisfrom,
            meta: {
              title: "联系我们",
            },
          },
        ],
      },
      {
        path: "/relation",
        name: "relation",
        component: relation,
      },
      {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
          title: "首页",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// 导入公共文件
import defaultSetting from "@/setting/setting.js";
router.beforeEach((to, from, next) => {
  // 用于设置 浏览器的 title 显示
  if (to.meta.title) {
    document.title = `${to.meta.title}-${defaultSetting.title}`;
  } else {
    document.title = `${defaultSetting.title}`;
  }
  next();
});

export default router;
