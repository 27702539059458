<template>
  <div>
    <about></about>
    <div class="text animate__animated animate__backInDown">
      <div class="introduce_title">
        <span><img src="../../assets/img/xian-left.png" alt="" /></span>
        <span>&nbsp;&nbsp;&nbsp;联系我们&nbsp;&nbsp;&nbsp;</span>
        <span
          ><img src="../../assets/img/xian-right.png" alt="" srcset=""
        /></span>
      </div>
    </div>
    <div class="code animate__animated animate__backInDown">
      <span>
        <div class="code_left">
          <span class="iconfont icon-shopping"></span>
          <div class="code_left_cn">
            <span>商务合作(购买产品/项目合作)</span>
            <p><i>联系人</i>：王先生</p>
            <p><i>电话</i>：13636710212</p>
            <p><i>邮箱</i>：1958337293@qq.com</p>
          </div>
        </div>
        <div class="code_rigt">
          <span class="iconfont icon-hezuohuoban"></span>
          <div class="code_left_cn">
            <span>人才招聘</span>
            <p><i>联系人</i>：江女士</p>
            <p><i>电话</i>：18192088826</p>
            <p><i>邮箱</i>：1061254699@qq.com</p>
            <br />
          </div>
        </div>
      </span>
      <div class="wxAccounts">
        <p>微信公众号</p>
        <i>
          <img src="../../assets/img/erweima.jpg" alt="" srcset="" />
        </i>
      </div>
    </div>
    <collect></collect>
    <records></records>
  </div>
</template>

<script>
import Map from "../../components/Map/Map.vue";
import about from "../../components/abouttitle/abouttitle.vue";
import collect from "../../components/collect/collect.vue";
import records from "../../components/records/records.vue";
export default {
  name: "advertisfrom",
  data() {
    return {
      isCollapsed: false,
      files: "",
    };
  },
  components: {
    about,
    collect,
    records,
    Map,
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.text {
  text-align: center;
  margin-top: 30px;
}
.code {
  width: 100%;
  margin-top: 50px;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  .code_left,
  .code_rigt {
    display: flex;
    .icon-shopping,
    .icon-hezuohuoban {
      font-size: 30px;
    }
    .code_left_cn {
      line-height: 30px;
      margin-left: 15px;
      p {
        font-size: 17px;
        i {
          display: inline-block;
          width: 3em;
          text-align: justify;
          text-align-last: justify;
        }
      }
    }
  }
  .code_rigt {
    margin-top: 10px;
  }
  .wxAccounts {
    margin-top: 40px;
    text-align: center;
    i {
      img {
        width: 150px;
        margin-top: 15px;
      }
    }
  }
}
</style>
