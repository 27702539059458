<template>
  <!-- 多媒体电子沙盘 -->
  <div class="home">
    <!-- 开头  -->
    <titleone>
      <template #multimedia>
        <div class="title_left animate__animated animate__backInDown">
          <span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以城市规划图为指导制作交通道路、江河湖水系，制作城市景观模型，对城市重要目标、重要单位、
            重要专题要素重点体现，同时搭配沙盘灯光电路效果，分路交互控制展示。</span
          >
        </div>
        <div class="title_righgt animate__animated animate__backInLeft">
          <img src="../../assets/img/map_banner.png" alt="" srcset="" />
        </div>
      </template>
    </titleone>
    <!-- 产品介绍  -->
    <div class="introduce animate__animated animate__backInLeft">
      <div class="introduce_title">
        <span><img src="../../assets/img/xian-left.png" alt="" /></span>
        <span>&nbsp;&nbsp;&nbsp;产品介绍&nbsp;&nbsp;&nbsp;</span>
        <span
          ><img src="../../assets/img/xian-right.png" alt="" srcset=""
        /></span>
      </div>
      <div class="introduce_img">
        <div class="">
          <img src="../../assets/img/1.png" alt="" srcset="" />
          <p>地形沙盘</p>
          <i
            >采用三维数控雕刻制作城市主城区及周边区域地形地貌区域。
            以城市规划图为指导制作交通道路等制作城市景观模型。</i
          >
        </div>
        <div class="">
          <img src="../../assets/img/9.png" alt="" srcset="" />
          <p>3D打印沙盘沙盘</p>
          <i
            >根据真实的地理信息数据，和先进3D打印技术手段，
            实现地形地貌场景真色彩、高精度、高还原。</i
          >
        </div>
        <div class="">
          <img src="../../assets/img/2.jpg" alt="" srcset="" />
          <p>升降沙盘</p>
          <i
            >定做沙盘钢结构沙盘基座，安装固定在沙盘专用电动升降机上，实现沙盘盘面垂直升降；实现空间的合理运用。</i
          >
        </div>
        <div class="">
          <img src="../../assets/img/4.png" alt="" srcset="" />
          <p>光电互动沙盘</p>
          <i
            >以精细的实体模型为基础，结合声、光、电、影像，通过定制的同步控制软件控制实体模型内置灯光，
            达成与使用者的交互操作，虚实结合，更好的展示综合信息。</i
          >
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <collect></collect>
    <records></records>
  </div>
</template>

<script>
import titleone from "../../components/title/titleone.vue";
import collect from "../../components/collect/collect.vue";
import records from "../../components/records/records.vue";
export default {
  name: "productList",
  data() {
    return {};
  },
  components: {
    records,
    titleone,
    collect,
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.home {
  img {
    pointer-events: none;
  }
  .introduce {
    .introduce_title {
      margin-top: 30px;
      text-align: center;
      span {
        margin-top: 15px;
      }
    }
    .introduce_img {
      display: flex;
      margin-top: 20px;
      justify-content: space-around;
      padding: 3% 6% 5px 6%;
      img {
        width: 240px;
        height: 160px;
        margin-top: 10px;
      }
      img:hover {
        transform: scale(1.1);
      }
      p {
        margin-top: 10px;
        font-size: 25px;
      }
      div {
        width: 22%;
        text-align: center;
      }
      i {
        font-size: 20px;
      }
    }
  }
}
</style>
