<template>
  <div>
    <div class="title">
      <slot name="multimedia"></slot>
      <slot name="dimensional"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "titleone",
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.title {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #171b2c;
  .title_left {
    width: 50%;
    font-size: 25px;
    color: white;
    span {
      white-space: pre-line;
      line-height: 35px;
    }
    // p {
    //   margin-top: 10px;
    // }
  }
  .title_right {
    img {
      width: 50px;
    }
  }
}
</style>
