import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 初始化样式表
import "@/assets/css/basic.css";
// 图标库
import "@/assets/download/iconfont.css";
//css动画库
import "animate.css";
// 引入ElementUI组件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 引入axios
import axios from "axios";
//导入utils
import utils from "../src/utils";

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$utils = utils;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
