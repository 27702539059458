<template>
  <div>
    <csae></csae>
    <div class="text animate__animated animate__backInDown">
      <div class="introduce_title">
        <span><img src="../../assets/img/xian-left.png" alt="" /></span>
        <span>&nbsp;&nbsp;&nbsp;军队武警&nbsp;&nbsp;&nbsp;</span>
        <span
          ><img src="../../assets/img/xian-right.png" alt="" srcset=""
        /></span>
      </div>
    </div>
    <div class="row animate__animated animate__backInDown">
      <el-row>
        <el-card :body-style="{ padding: '0px' }">
          <img src="../../assets/img/7.jpg" class="image" />
          <div style="padding: 14px" class="title">
            <div class="bottom clearfix">
              <span>作战室联动沙盘</span>
              <el-button type="text" class="button">军队武警</el-button>
            </div>
          </div>
        </el-card>
      </el-row>
      <el-row>
        <el-card :body-style="{ padding: '0px' }">
          <img src="../../assets/img/25.jpg" class="image" />
          <div style="padding: 14px" class="title">
            <div class="bottom clearfix">
              <span>指挥室电子沙盘</span>
              <el-button type="text" class="button">军队武警</el-button>
            </div>
          </div>
        </el-card>
      </el-row>
      <el-row>
        <el-card :body-style="{ padding: '0px' }">
          <img src="../../assets/img/21.png" class="image" />
          <div style="padding: 14px" class="title">
            <div class="bottom clearfix">
              <span>模拟室沙盘</span>
              <el-button type="text" class="button">军队武警</el-button>
            </div>
          </div>
        </el-card>
      </el-row>
      <el-row>
        <el-card :body-style="{ padding: '0px' }">
          <img src="../../assets/img/22.png" class="image" />
          <div style="padding: 14px" class="title">
            <div class="bottom clearfix">
              <span>军事院校演练沙盘</span>
              <el-button type="text" class="button">军队武警</el-button>
            </div>
          </div>
        </el-card>
      </el-row>
      <el-row>
        <el-card :body-style="{ padding: '0px' }">
          <img src="../../assets/img/23.png" class="image" />
          <div style="padding: 14px" class="title">
            <div class="bottom clearfix">
              <span>某部作战地形沙盘</span>
              <el-button type="text" class="button">军队武警</el-button>
            </div>
          </div>
        </el-card>
      </el-row>
      <el-row>
        <el-card :body-style="{ padding: '0px' }">
          <img src="../../assets/img/24.png" class="image" />
          <div style="padding: 14px" class="title">
            <div class="bottom clearfix">
              <span>某部训练多屏联动沙盘</span>
              <el-button type="text" class="button">军队武警</el-button>
            </div>
          </div>
        </el-card>
      </el-row>
    </div>
    <collect></collect>
    <records></records>
  </div>
</template>

<script>
import csae from "../../components/case/case.vue";
import collect from "../../components/collect/collect.vue";
import records from "../../components/records/records.vue";
export default {
  name: "orderformList",
  data() {
    return {
      img: [{ url: "../../assets/img/7.png" }],
    };
  },
  components: {
    collect,
    records,
    csae,
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
div {
  img {
    pointer-events: none;
  }
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  height: 230px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.title {
  font-size: 20px;
}
.el-row {
  width: 30%;
  margin-top: 3%;
}
.row {
  padding: 3% 7% 3% 7%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.text {
  text-align: center;
  margin-top: 30px;
}
</style>
