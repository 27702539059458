<template>
  <div class="index">
    <about></about>
    <!-- 匠心制作 -->
    <div class="animate__animated animate__backInLeft originality">
      <!-- 标题 -->
      <div class="introduce_title text">
        <span><img src="../../assets/img/xian-left.png" alt="" /></span>
        <span>&nbsp;&nbsp;&nbsp;匠心制作&nbsp;&nbsp;&nbsp;</span>
        <span
          ><img src="../../assets/img/xian-right.png" alt="" srcset=""
        /></span>
      </div>
      <div class="course">
        <div>
          <img src="../../assets//img/about-icon01.png" alt="" srcset="" />
          <p>打造卓越产品</p>
          <span>支持定制化服务，满足多元化需求</span>
        </div>
        <div>
          <img src="../../assets//img/about-icon03.png" alt="" srcset="" />
          <p>简单 实用 高效</p>
          <span>服务于国防动员、军事、水利等相关行业</span>
        </div>
        <div>
          <img src="../../assets//img/about-icon05.png" alt="" srcset="" />
          <p>合作 共赢</p>
          <span>全天候24小时提供匠心服务</span>
        </div>
      </div>
    </div>
    <!-- 产品和服务 -->
    <div>
      <!-- 标题 -->
      <div class="text animate__animated animate__backInDown">
        <div class="introduce_title">
          <span><img src="../../assets/img/xian-left.png" alt="" /></span>
          <span>&nbsp;&nbsp;&nbsp;产品和服务&nbsp;&nbsp;&nbsp;</span>
          <span
            ><img src="../../assets/img/xian-right.png" alt="" srcset=""
          /></span>
        </div>
      </div>
      <!-- 卡片 -->
      <div class="product">
        <span>
          <div class="product_lf">
            <!-- <el-card class="box-card">
            <div class="texts item"> -->
            <h3>德昌地理信息服务</h3>
            <p>
              采用高性能多源地理信息服务系统，提高网络化部署和发布多源地图数据服务性能，提供网络多并发下的多源地理信息数据服务、网络地图服务。系统允许用户查看和编辑多源地理空间数据，满足导入导出符合开放地理信息标准的数据；在地图创建和数据共享方面具有灵活性，可采用
              Web 地图服务（WMS）标准创建各种输出格式的地图。
              管理符合WFS、WCS标准的多源地理信息数据，允许网络共享和编辑生成地图的多源地理信息数据。支持使用WMTS标准对发布的地图切分网络化
              Web 地图调用使用。
            </p>
            <!-- </div>
          </el-card> -->
          </div>
          <div class="product_rg">
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/DCEarthServer_1.png" class="image" />
              </el-card>
            </el-row>
          </div>
        </span>
        <div class="product_img">
          <el-row>
            <el-card :body-style="{ padding: '0px' }">
              <img src="../../assets/img/DC_1.png" class="image" />
            </el-card>
          </el-row>
          <el-row>
            <el-card :body-style="{ padding: '0px' }">
              <img src="../../assets/img/DC_2.png" class="image" />
            </el-card>
          </el-row>
          <el-row>
            <el-card :body-style="{ padding: '0px' }">
              <img src="../../assets/img/DC_4.png" class="image" />
            </el-card>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 特色功能 -->
    <div>
      <!-- 标题 -->
      <div class="text animate__animated animate__backInDown">
        <div class="introduce_title">
          <span><img src="../../assets/img/xian-left.png" alt="" /></span>
          <span>&nbsp;&nbsp;&nbsp;特色功能&nbsp;&nbsp;&nbsp;</span>
          <span
            ><img src="../../assets/img/xian-right.png" alt="" srcset=""
          /></span>
        </div>
      </div>
      <!-- 卡片 -->
      <div class="product_s">
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/yy_1.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix" style="text-align: center">
                <span>语音识别</span>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/bd.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix" style="text-align: center">
                <span>支持北斗接口</span>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/BIM智能化运维.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix" style="text-align: center">
                <span>BIM智能化运维</span>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/三维倾斜摄影.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix" style="text-align: center">
                <span>三维倾斜摄影</span>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img
              src="../../assets/img/电子地图与地理信息系统.png"
              class="image"
            />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix" style="text-align: center">
                <span>电子地图与地理信息系统</span>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/三维浏览.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix" style="text-align: center">
                <span>三维浏览</span>
              </div>
            </div>
          </el-card>
        </el-row>
      </div>
    </div>
    <!-- 全息交互 -->
    <div>
      <!-- 标题 -->
      <div class="text animate__animated animate__backInDown">
        <div class="introduce_title">
          <span><img src="../../assets/img/xian-left.png" alt="" /></span>
          <span>&nbsp;&nbsp;&nbsp;全息交互沙盘&nbsp;&nbsp;&nbsp;</span>
          <span
            ><img src="../../assets/img/xian-right.png" alt="" srcset=""
          /></span>
        </div>
      </div>
      <!-- 卡片 -->
      <div class="product_s">
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/qx.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix" style="text-align: center">
                <span>全息投影互动沙盘</span>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/全息互交沙盘.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix" style="text-align: center">
                <span>全息互交沙盘</span>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/全息互交训练沙盘 .png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix" style="text-align: center">
                <span>全息互交训练沙盘</span>
              </div>
            </div>
          </el-card>
        </el-row>
      </div>
    </div>
    <collect></collect>
    <records></records>
  </div>
</template>

<script>
import about from "../../components/abouttitle/abouttitle.vue";
import collect from "../../components/collect/collect.vue";
import records from "../../components/records/records.vue";
export default {
  name: "index",
  data() {
    return {};
  },
  methods: {},
  components: {
    collect,
    records,
    about,
  },
};
</script>
<style lang="less" scoped>
.index {
  position: relative;
  img {
    pointer-events: none;
  }
}
.el-card {
  min-width: 380px;
  margin-right: 20px;
  transition: all 0.1s;
}
.el-card:hover {
  margin-top: -5px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.text {
  text-align: center;
  margin-top: 30px;
}
.item {
  padding: 10px 0;
}
.originality {
  .course {
    padding: 5% 7% 5% 7%;
    font-size: 20px;
    display: flex;
    text-align: center;
    justify-content: space-around;
    line-height: 35px;
  }
}
.card {
  display: flex;
  line-height: 70px;
  padding: 4% 7%;
  text-align: center;
  justify-content: space-around;
  .box-card {
    font-size: 25px;
    width: 380px;
  }
  span {
    display: block;
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 30px;
    margin: 0 auto;
    border-radius: 100px;
  }
  .icon-sanwei {
    background-color: #007cdd;
    color: white;
  }
  .icon-rocket-full {
    background-color: #12a09e;
    color: white;
  }
  .icon-fuwu {
    background-color: #5689f6;
    color: white;
  }
  p {
    font-size: 18px;
  }
}
.product {
  padding: 4% 7%;
  .box-card {
    width: 500px;
  }
  span {
    display: flex;
    text-align: left;
    font-size: 25px;
    justify-content: space-around;
    .product_lf {
      width: 50%;
      p {
        margin-top: 15px;
        font-size: 18px;
        line-height: 30px;
        text-indent: 2em;
      }
    }
    .product_rg {
      padding-left: 70px;
      img {
        width: 100%;
        height: 250px;
        margin-top: 10px;
      }
      .el-row {
        padding-left: 85px;
        margin-top: 10px;
      }
    }
  }
  .product_img {
    display: flex;
    margin-top: 30px;
    img {
      width: 100%;
      height: 250px;
      margin-top: 10px;
    }
    .el-row {
      flex: 1;
      padding-left: 10px;
      margin-top: 10px;
    }
  }
}
.product_s {
  padding: 3% 7% 3% 7%;
  font-size: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .el-row {
    width: 30%;
    margin-top: 3%;
  }
  img {
    width: 100%;
    height: 260px;
    margin-top: 10px;
  }
}
</style>
