<template>
  <div>
    <h1>联系我们</h1>
  </div>
</template>

<script>
export default {
  name: "relation",
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
