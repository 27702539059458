<template>
  <div>
    <div class="title">
      <div class="title_left animate__animated animate__backInDown">
        <p>忠诚·务实·高效·共赢</p>
      </div>
    </div>
    <div class="title_right"></div>
  </div>
</template>

<script>
export default {
  name: "abouttitle",
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.title {
  margin: 0px;
  color: white;
  background: url(../../assets/img/11.jpg) no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  //   justify-content: space-around;
  .title_left {
    padding-left: 7%;
    font-size: 35px;
    text-align: center;
    p {
      margin-top: 10px;
    }
  }
}
</style>
