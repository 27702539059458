<template>
  <div>
    <div class="records">
      <span
        >Copyright © 2017-2023 西安德昌信息科技有限公司 All Rights
        Reserved&nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;
        <a href="http://beian.miit.gov.cn/"> 陕ICP备2021011413号-1</a></span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "records",
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.records {
  height: 60px;
  text-align: center;
  line-height: 60px;
  background-color: rgb(248, 246, 246);

  span {
    font-size: 20px;
    a {
      color: black;
    }
  }
}
</style>
