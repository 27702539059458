<template>
  <div>
    <csae></csae>
    <!-- 水文水利 -->
    <div>
      <div class="text animate__animated animate__backInDown">
        <div class="introduce_title">
          <span><img src="../../assets/img/xian-left.png" alt="" /></span>
          <span>&nbsp;&nbsp;&nbsp;水文水利&nbsp;&nbsp;&nbsp;</span>
          <span
            ><img src="../../assets/img/xian-right.png" alt="" srcset=""
          /></span>
        </div>
      </div>
      <div>
        <div class="row animate__animated animate__backInDown">
          <el-row>
            <el-card :body-style="{ padding: '0px' }">
              <img src="../../assets/img/2_qt.png" class="image" />
              <div style="padding: 14px" class="title">
                <div class="bottom clearfix">
                  <span>水利大数据综合管理应用平台</span>
                  <el-button type="text" class="button">水文水利</el-button>
                </div>
              </div>
            </el-card>
          </el-row>
          <el-row>
            <el-card :body-style="{ padding: '0px' }">
              <img src="../../assets/img/3_qt.png" class="image" />
              <div style="padding: 14px" class="title">
                <div class="bottom clearfix">
                  <span>智慧水利大数据平台</span>
                  <el-button type="text" class="button">水文水利</el-button>
                </div>
              </div>
            </el-card>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 地灾预警 -->
    <div>
      <div class="text animate__animated animate__backInDown">
        <div class="introduce_title">
          <span><img src="../../assets/img/xian-left.png" alt="" /></span>
          <span>&nbsp;&nbsp;&nbsp;地灾监测&nbsp;&nbsp;&nbsp;</span>
          <span
            ><img src="../../assets/img/xian-right.png" alt="" srcset=""
          /></span>
        </div>
      </div>
      <div>
        <div class="row animate__animated animate__backInDown">
          <el-row>
            <el-card :body-style="{ padding: '0px' }">
              <img src="../../assets/img/dz_1.png" class="image" />
              <div style="padding: 14px" class="title">
                <div class="bottom clearfix">
                  <span>某地质灾害预警大数据监测平台</span>
                  <el-button type="text" class="button">地质灾害平台</el-button>
                </div>
              </div>
            </el-card>
          </el-row>
          <el-row>
            <el-card :body-style="{ padding: '0px' }">
              <img src="../../assets/img/dz_2.png" class="image" />
              <div style="padding: 14px" class="title">
                <div class="bottom clearfix">
                  <span>某地质灾害预警大数据监测平台</span>
                  <el-button type="text" class="button">地质灾害平台</el-button>
                </div>
              </div>
            </el-card>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 智能监测 -->
    <div>
      <div class="text animate__animated animate__backInDown">
        <div class="introduce_title">
          <span><img src="../../assets/img/xian-left.png" alt="" /></span>
          <span>&nbsp;&nbsp;&nbsp;智能监测&nbsp;&nbsp;&nbsp;</span>
          <span
            ><img src="../../assets/img/xian-right.png" alt="" srcset=""
          /></span>
        </div>
      </div>
      <div class="row animate__animated animate__backInDown">
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/6_qt.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>海绵城市智能监测与管控</span>
                <el-button type="text" class="button">智能监测</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/4_qt.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>管廊智能监测与管控</span>
                <el-button type="text" class="button">智能监测</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
      </div>
    </div>
    <!-- 气象监测 -->
    <div>
      <div class="text animate__animated animate__backInDown">
        <div class="introduce_title">
          <span><img src="../../assets/img/xian-left.png" alt="" /></span>
          <span>&nbsp;&nbsp;&nbsp;气象监测&nbsp;&nbsp;&nbsp;</span>
          <span
            ><img src="../../assets/img/xian-right.png" alt="" srcset=""
          /></span>
        </div>
      </div>
      <div class="row animate__animated animate__backInDown">
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/qx_1.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>气象信息智能监测</span>
                <el-button type="text" class="button">气象监测</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/qx_2.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>气象信息智能监测</span>
                <el-button type="text" class="button">气象监测</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
      </div>
    </div>
    <!-- 其它 -->
    <div>
      <div class="text animate__animated animate__backInDown">
        <div class="introduce_title">
          <span><img src="../../assets/img/xian-left.png" alt="" /></span>
          <span>&nbsp;&nbsp;&nbsp;其它案例&nbsp;&nbsp;&nbsp;</span>
          <span
            ><img src="../../assets/img/xian-right.png" alt="" srcset=""
          /></span>
        </div>
      </div>
      <div class="row animate__animated animate__backInDown">
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/ap_1.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>掌上人防宣教APP</span>
                <el-button type="text" class="button">其它案例</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/ap_2.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>人防公众号/小程序</span>
                <el-button type="text" class="button">其他案例</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
      </div>
    </div>
    <collect></collect>
    <records></records>
  </div>
</template>

<script>
import csae from "../../components/case/case.vue";
import collect from "../../components/collect/collect.vue";
import records from "../../components/records/records.vue";
export default {
  name: "orderformClassify",
  data() {
    return {};
  },
  components: {
    collect,
    records,
    csae,
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
div {
  img {
    pointer-events: none;
  }
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  height: 230px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.title {
  font-size: 16px;
}
.text {
  text-align: center;
  margin-top: 30px;
}
.el-row {
  width: 30%;
  margin-top: 3%;
}
.row {
  padding: 3% 7% 3% 7%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
</style>
