<template>
  <div>
    <div class="collect">
      <div>
        <p>产品</p>
        <ul>
          <li><a href="/productlist#/productlist">多媒体电子沙盘</a></li>
          <li><a href="/productlist#/productclassify">三维虚拟数字地球</a></li>
          <!-- <li><a href="/productlist#/productone">智能交互控制系统</a></li> -->
        </ul>
      </div>
      <div class="phon">
        <span class="iconfont icon-dingweiweizhi"></span>
        <div class="number">
          <p>陕西省西安市&nbsp;雁塔区</p>

          <p>高新路25号（科技路地铁站F口步行360米）</p>
        </div>
      </div>
      <div class="phon">
        <span class="iconfont icon-dianhua1"></span>
        <div class="number">
          <h2>13636710212</h2>
          <p>欢迎来电 (正常工作时间内)</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "collect",
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.collect {
  // width: 100vw;
  height: 120px;
  font-size: 18px;
  background-color: rgb(248, 246, 246);
  margin-top: 80px;
  border-bottom: 2px solid white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  ul {
    li {
      margin-top: 5px;
      a:hover {
        color: rgb(235, 155, 50);
      }
      a {
        color: black;
      }
    }
  }
  .phon {
    display: flex;
    .icon-dianhua1,
    .icon-dingweiweizhi {
      font-size: 30px;
      margin-top: 2px;
    }
    .number {
      margin-left: 10px;
    }
  }
}
</style>
