<template>
  <div id="map">
    <div class="map-container" id="map-container"></div>
  </div>
</template>
<script>
export default {
  name: "leaflet",
  data() {
    return {
      map: null,
      OSMUrl: "http://192.168.0.189:8888/satellite_tdmap/{z}/{x}/{y}.png",
    };
  },
  components: {},
  methods: {},
  mounted() {
    this.map = this.$utils.map.createMap("map-container");
    // 设施地图视图 中心位置
    this.map.setView([34.236659404992295, 108.90260120653498], 13);
    // 加载 open street map和mapbox 图层服务
    this.$utils.map.createTileLayer(this.map, this.OSMUrl, { maxZoom: 19 });
    function onMapClick(e) {
      console.log(e);
    }
    this.map.on("click", onMapClick);
  },
};
</script>

<style lang="less" scoped>
.map-container {
  position: absolute;
  left: 0;
  // top: 20%; //添加100px间距，把上面的导航栏让出来
  width: 50%;
  height: 500px;
}
</style>
