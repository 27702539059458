<template>
  <!-- 三维虚拟数字地球 -->
  <div>
    <!-- 开头部分 -->
    <titleone>
      <template #dimensional>
        <div class="title_left animate__animated animate__backInDown">
          <span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;德昌信科三维数字沙盘引擎系统采用自主可控软硬件平台应用，提供了从全球至全县的宏观、广域三维数字地图
            ，以高分辨率卫星实景地图准确展示场景详情，为各级现地指挥和开展具体计算提供高清详实的三维数字地图服务。</span
          >
        </div>
        <div class="title_righgt animate__animated animate__backInLeft">
          <img src="../../assets/img/map3d7.png" alt="" srcset="" />
        </div>
      </template>
    </titleone>
    <!-- 产品介绍 -->
    <div class="introduce ntroduce animate__animated animate__backInLeft">
      <div class="introduce_title">
        <span><img src="../../assets/img/xian-left.png" alt="" /></span>
        <span>&nbsp;&nbsp;&nbsp;产品介绍&nbsp;&nbsp;&nbsp;</span>
        <span
          ><img src="../../assets/img/xian-right.png" alt="" srcset=""
        /></span>
      </div>
      <!-- 作战指挥室电子沙盘系统 -->
      <div class="introduce_img">
        <div class="introduce_introduce">
          <div class="introduce_introduce_lf">
            <h3 style="text-align: center">作战指挥室电子沙盘系统</h3>
            <i>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;自主可控软硬件平台、采用系统提供的测绘地理量距、
              量面、等高线、坡度坡向等计算计量工具提供必要数据，直观精准地进行地形分析。支持武警、人防、应急、公安、消防标准的二维和三维矢量军标库。兼容主流监控设备、具备人员管理、视频追踪、场景回溯等使用功能，同时兼容倾斜摄影模型，三维模型，可以较好的反映城市场景。且系统可多用户同时在线协同作业，可以进行行动部署和复盘。
            </i>
            <div class="introduce_introduce_lf_row">
              <el-row style="padding-right: 10px">
                <el-card :body-style="{ padding: '0px' }">
                  <img src="../../assets/img/1_zz.png" class="image" />
                  <div class="title">
                    <div class="bottom clearfix" style="text-align: center">
                      <span>量算分析展示</span>
                    </div>
                  </div>
                </el-card>
              </el-row>
              <el-row>
                <el-card :body-style="{ padding: '0px' }">
                  <img src="../../assets/img/2_zz.png" class="image" />
                  <div class="title">
                    <div class="bottom clearfix" style="text-align: center">
                      <span>导入模型编辑</span>
                    </div>
                  </div>
                </el-card>
              </el-row>
            </div>
          </div>
          <div class="introduce_introduce_rg">
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/34.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>系统页面展示</span>
                  </div>
                </div>
              </el-card>
            </el-row>
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/4_zz.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>支持北斗信号</span>
                  </div>
                </div>
              </el-card>
            </el-row>
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/5_zz.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>多人协同标绘展示</span>
                  </div>
                </div>
              </el-card>
            </el-row>
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/3_zz.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>支持军标导入</span>
                  </div>
                </div>
              </el-card>
            </el-row>
          </div>
        </div>
      </div>
      <!-- 武警三维电子沙盘系统 -->
      <div class="introduce_img">
        <div class="introduce_introduce">
          <div class="introduce_introduce_rg inleft">
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/1_wj.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>系统页面展示</span>
                  </div>
                </div>
              </el-card>
            </el-row>
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/5_wj.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>支持外接视频监控</span>
                  </div>
                </div>
              </el-card>
            </el-row>
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/w_wj.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>支持军标符号库</span>
                  </div>
                </div>
              </el-card>
            </el-row>
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/3_wj.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>重点目标信息展示</span>
                  </div>
                </div>
              </el-card>
            </el-row>
          </div>
          <div class="introduce_introduce_lf intr">
            <h3 style="text-align: center">三维电子沙盘系统</h3>
            <i>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;自主可控软硬件平台、提供测绘地理量距、量面、等高线、坡度坡向等计算计量工具，直观精准地进行地形分析。支持武警、人防、应急、公安、消防标准的二维和三维矢量军标库。
              兼容主流监控设备、具备人员管理、视频追踪、场景回溯等使用功能，同时兼容倾斜摄影模型，三维模型，可以较好的反映城市场景。且系统可多用户同时在线协同作业，可以进行行动部署和复盘。
            </i>
          </div>
        </div>
      </div>
      <!-- RF三维数字沙盘系统 -->
      <div class="introduce_img">
        <div class="introduce_introduce">
          <div class="introduce_introduce_lf intr">
            <h3 style="text-align: center">三维数字沙盘系统</h3>
            <i>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;自主可控软硬件平台、采用系统提供的测绘地理量距、量面、
              等高线、坡度坡向等计算计量工具提供必要数据，直观精准地进行地形分析。支持模型导入、兼容主流摄像头。具备人防专题数据包，支持武警、人防、应急、公安、消防标准的二维和三维矢量军标库，实现人防要素体现。
              且系统可多用户同时在线协同作业，可以进行行动部署和复盘。
            </i>
          </div>
          <div class="introduce_introduce_rg">
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/1_rf.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>系统页面展示</span>
                  </div>
                </div>
              </el-card>
            </el-row>
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/2_rf.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>人口疏散标绘推演</span>
                  </div>
                </div>
              </el-card>
            </el-row>
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/3_rf.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>区域重点信息查询</span>
                  </div>
                </div>
              </el-card>
            </el-row>
            <el-row>
              <el-card :body-style="{ padding: '0px' }">
                <img src="../../assets/img/4_rf.png" class="image" />
                <div class="title">
                  <div
                    class="bottom clearfix"
                    style="text-align: center; padding: 12px"
                  >
                    <span>支持人防符号库</span>
                  </div>
                </div>
              </el-card>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <collect></collect>
    <records></records>
  </div>
</template>

<script>
import collect from "../../components/collect/collect.vue";
import records from "../../components/records/records.vue";
import titleone from "../../components/title/titleone.vue";
export default {
  name: "productClassify",
  data() {
    return {};
  },
  components: {
    titleone,
    records,
    collect,
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.introduce {
  img {
    pointer-events: none;
  }
  background-color: aliceblue;
  .introduce_title {
    padding-top: 30px;
    text-align: center;
    span {
      margin-top: 15px;
    }
  }
  .introduce_img {
    margin-top: 20px;
    font-size: 20px;
    padding: 3% 6% 3% 6%;
    .introduce_introduce {
      display: flex;
      justify-content: space-around;
      .introduce_introduce_lf {
        width: 40%;
        line-height: 35px;
        .introduce_introduce_lf_row {
          display: flex;
          margin-top: 20px;
          img {
            width: 100%;
            height: 150px;
            margin-top: 10px;
          }
        }
      }
      .intr {
        margin: auto;
      }
      .inleft {
        padding-right: -80px;
      }
      .introduce_introduce_rg {
        width: 55%;
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;
        img {
          width: 100%;
          height: 180px;
          margin-top: 10px;
        }
        .el-row {
          width: 45%;
          margin-top: 10px;
        }
        .row {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-around;
        }
      }
    }
    img {
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
