<template>
  <div>
    <csae></csae>
    <div class="text animate__animated animate__backInDown">
      <div class="introduce_title">
        <span><img src="../../assets/img/xian-left.png" alt="" /></span>
        <span>&nbsp;&nbsp;&nbsp;国动人防&nbsp;&nbsp;&nbsp;</span>
        <span
          ><img src="../../assets/img/xian-right.png" alt="" srcset=""
        /></span>
      </div>
    </div>
    <div>
      <div class="row animate__animated animate__backInDown">
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/27.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>人防预案信息管理系统</span>
                <el-button type="text" class="button">国动人防</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/28.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>人防战备训练三维仿真推演系统</span>
                <el-button type="text" class="button">国动人防</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/29.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>人防综合数据可视化管理系统</span>
                <el-button type="text" class="button">国动人防</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/30.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>智慧人防综合管理与协同办公系统</span>
                <el-button type="text" class="button">国动人防</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/33.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>多媒体电子沙盘系统</span>
                <el-button type="text" class="button">国动人防</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card :body-style="{ padding: '0px' }">
            <img src="../../assets/img/32.png" class="image" />
            <div style="padding: 14px" class="title">
              <div class="bottom clearfix">
                <span>智慧人防平战结合管理智能监管系统</span>
                <el-button type="text" class="button">国动人防</el-button>
              </div>
            </div>
          </el-card>
        </el-row>
      </div>
    </div>
    <collect></collect>
    <records></records>
  </div>
</template>

<script>
import csae from "../../components/case/case.vue";
import collect from "../../components/collect/collect.vue";
import records from "../../components/records/records.vue";
export default {
  name: "orderformClassify",
  data() {
    return {};
  },
  components: {
    collect,
    records,
    csae,
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
div{
  img{
   pointer-events:none;
}
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  height: 230px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.title {
  font-size: 16px;
}
.text {
  text-align: center;
  margin-top: 30px;
}
.el-row {
  width: 30%;
  margin-top: 3%;
}
.row {
  padding: 3% 7% 3% 7%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
</style>
