import { render, staticRenderFns } from "./orderformclassify.vue?vue&type=template&id=c8442652&scoped=true&"
import script from "./orderformclassify.vue?vue&type=script&lang=js&"
export * from "./orderformclassify.vue?vue&type=script&lang=js&"
import style0 from "./orderformclassify.vue?vue&type=style&index=0&id=c8442652&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8442652",
  null
  
)

export default component.exports