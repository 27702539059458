<template>
  <div class="Home">
    <div class="line">
      <el-backtop :visibility-height="200" :right="40" :bottom="60">
        <div class="iconfont icon-xiangshang"></div>
      </el-backtop>
    </div>
    <el-menu
      class="el-menu-demo"
      mode="horizontal"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
    >
      <el-menu-item class="logon">
        <img src="../assets/img/logo.png" alt="" srcset="" />
        <span>德昌信科</span>
      </el-menu-item>
      <el-menu-item index="/">首页</el-menu-item>
      <el-menu-item index="/advertisinglist">公司简介</el-menu-item>
      <el-submenu v-for="meau in meaus" :key="meau.name" :index="meau.name">
        <template slot="title">
          <span slot="title">{{ meau.name }}</span>
        </template>
        <el-menu-item-group v-for="item in meau.children" :key="item.name">
          <el-menu-item :index="item.path">
            <span>{{ item.name }}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-menu-item index="/advertisfrom">联系我们</el-menu-item>
    </el-menu>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      isCollapse: false,
      meaus: [
        {
          name: "产品",
          path: "/product",
          icon: "icon-product",
          children: [
            {
              name: "三维虚拟数字地球",
              path: "/productclassify",
              icon: "icon-category",
            },
            {
              name: "多媒体电子沙盘",
              path: "/productlist",
              icon: "icon-list2",
            },
          ],
        },
        {
          name: "项目案例",
          path: "/orderform",
          icon: "icon-order",
          children: [
            {
              name: "军队武警",
              path: "/orderformlist",
              icon: "icon-list",
            },
            {
              name: "国动人防",
              path: "/orderformclassify",
              icon: "icon-summary",
            },
            {
              name: "其他案例",
              path: "/orderformaudit",
              icon: "icon-summary",
            },
          ],
        },
      ],
    };
  },
  components: {},
  methods: {
    showCollapse() {
      this.isCollapse = !this.isCollapse;
      console.log(this.isCollapse);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.Home {
  .el-backtop {
    width: 50px;
    height: 60px;

    border-radius: 23%;

    .icon-xiangshang {
      font-size: 30px;
    }
  }

  .el-header {
    background-color: #00a0fe;
    color: #333;
    line-height: 60px;
  }

  .el-menu-vertical-demo {
    h4 {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      color: white;
    }
  }

  /deep/.el-menu-item,
  /deep/.el-submenu__title {
    font-size: 20px;
    margin-left: 30px;
  }

  .logon {
    img {
      width: 45px;
      margin-left: 80px;
    }
  }
}
</style>
