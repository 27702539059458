<template>
  <div class="home">
    <about></about>
    <!-- 公司简介 -->
    <div class="understand animate__animated animate__backInLeft">
      <div class="text animate__animated animate__backInDown">
        <div class="introduce_title">
          <span><img src="../../assets/img/xian-left.png" alt="" /></span>
          <span>&nbsp;&nbsp;&nbsp;了解德昌&nbsp;&nbsp;&nbsp;</span>
          <span
            ><img src="../../assets/img/xian-right.png" alt="" srcset=""
          /></span>
        </div>
      </div>
      <!-- 公司发展史 -->
      <div class="course">
        <ul>
          <li id="icon1">
            <h2>2016</h2>
            <span>德昌信科成立</span>
          </li>
          <li id="icon2">
            <h2>20+</h2>
            <span>团队成员</span>
          </li>
          <li id="icon3">
            <h2>40+</h2>
            <span>自主知识产权</span>
          </li>
          <li id="icon4">
            <h2>20+</h2>
            <span>解决方案</span>
          </li>
          <li id="icon5">
            <h2>500+</h2>
            <span>服务客户</span>
          </li>
        </ul>
      </div>
      <!-- 公司简介 -->
      <div class="abstract">
        <p class="indent">
          西安德昌信息科技有限公司是<b>国家高新技术企业、双软企业、大数据企业、西安市级军民融合企业、科技中小型企业、拥有JGBM资格</b>，通过了ISO-9001质量体系认证，ISO27001信息安全管理体系认证、ISO20000信息技术服务管理体系认证、五星级售后服务认证和AAA信用评级认证。自成立以来，公司秉承“诚信经营，技术一流”的经营理念，始终以<b>“忠诚、务实、高效、共赢”</b>为宗旨。
        </p>
        <p class="indent">
          瞄准政务信息化、物联网、大数据、云计算等发展前沿，运用三维数控、沙盘模型、虚拟仿真、地理信息、测绘导航、云计算、大数据等信息技术，陆续承担完成了人防、水利、市政、地质灾害、军工等多行业领域的软件系统研发、电子沙盘制作以及信息化项目设计、集成与实施。公司重点以<b>人防、水利、军工</b>等行业的信息化应用需求为牵引进行深耕，特别以人防应急指挥平台、工程监测运维管理、北斗导航、预警警报等各类信息平台和基础设施为依托，融合智能监测运维、大数据、互联网、地理信息系统、虚拟仿真等技术，形成了人防二三维一张图、人防三维数字沙盘、人防业务综合管理、人防工程运维监测、人防指挥作业与训练演练、人防电子预案管理、“互联网+”人防宣教、人防VR模拟演练以及人防工程三维智能化监管等体系化产品与解决方案。同步在水利和军工领域研发了水利大数据平台、智慧水利综合信息集成展示平台、城市洪涝预警展示平台以及军用沙盘、军事训练沙盘等成产品与方案，获得了客户的高度认可与好评。
          经过多年发展，公司拥有二十余项知识产权和产品专利。未来，公司将以<b
            >“特别能创新”为引领，继续在人防、水利、军工等行业精耕细作，开拓创新。
          </b>
        </p>
      </div>
    </div>
    <!-- 荣誉介绍 -->
    <div class="honor">
      <div class="text animate__animated animate__backInDown">
        <div class="introduce_title">
          <span><img src="../../assets/img/xian-left.png" alt="" /></span>
          <span>&nbsp;&nbsp;&nbsp;荣誉铸就信赖&nbsp;&nbsp;&nbsp;</span>
          <span
            ><img src="../../assets/img/xian-right.png" alt="" srcset=""
          /></span>
        </div>
      </div>
      <div class="honor_img indent">
        <ul>
          <li>
            <span>
              <img src="../../assets/img/14.jpg" alt="" srcset="" />
            </span>
            <div class="honor_img_title">
              <h4>软件企业证书</h4>
              <span>
                根据《软件企业评估标准 T/SIA2002
                -2019》经陕西省软件行业协会专家评审组审核，我公司被评估为软件企业
              </span>
            </div>
          </li>
          <li>
            <span>
              <img src="../../assets/img/15.jpg" alt="" srcset="" />
            </span>
            <div>
              <h4>安防工程企业设计施工维护能力证书</h4>
              <span>
                获评安防工程企业设计施工维护能力评价资质
                是工程质量的保证，高服务水平的体现，同时也是为了促进安防行业持续健康发展。
              </span>
            </div>
          </li>
          <li>
            <span>
              <img src="../../assets/img/16.jpg" alt="" srcset="" />
            </span>
            <div>
              <h4>高新技术企业证书</h4>
              <span>
                根据国家《高新技术企业认定管理办法》的相关规定，经安徽省高新技术企业认定管理工作领导小组办公室组织专家评审，认定我司为高新技术企业。
              </span>
            </div>
          </li>
          <li>
            <span>
              <img src="../../assets/img/17.jpg" alt="" srcset="" />
            </span>
            <div>
              <h4>大数据企业证书</h4>
              <span
                >德昌信科通过了大数据领域的技术实力、核心自主知识产权、研发组织管理水平等能力的评估。
                经西安市大数据资源管理局颁发《西安市大数据企业》证书。</span
              >
            </div>
          </li>
          <li>
            <span>
              <img src="../../assets/img/18.png" alt="" srcset="" />
            </span>
            <div>
              <h4>AAA级信用企业证书</h4>
              <span>
                获评3A企业信用等级，是行业、客户对我们过去多年以来诚信经营、用心服务和创新发展的肯定，同时也是一种责任。
              </span>
            </div>
          </li>
          <li>
            <span>
              <img src="../../assets/img/19.jpg" alt="" srcset="" />
            </span>
            <div>
              <h4>售后服务认证证书</h4>
              <span>
                根据国家GB/T27922-2011 商品售后服务评价体系
                经数网信认证服务（北京）有限公司审核，我公司被评为五星级售后服务企业
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 专利加商标 -->
    <div class="honor">
      <div class="text animate__animated animate__backInDown">
        <div class="introduce_title">
          <span><img src="../../assets/img/xian-left.png" alt="" /></span>
          <span>&nbsp;&nbsp;&nbsp;时间成就品牌&nbsp;&nbsp;&nbsp;</span>
          <span
            ><img src="../../assets/img/xian-right.png" alt="" srcset=""
          /></span>
        </div>
      </div>
      <div class="honor_img indent patent_img">
        <ul>
          <li>
            <span>
              <img src="../../assets/img/华承百匠00.jpg" alt="" srcset="" />
            </span>
            <div style="margin-top: 20px">
              <h4>地图绘制服务</h4>
              <span>
                我司能够提供专业的地图绘制服务，同时具备专业高效的地图可视化数据生产团队，快速绘制各类场景的实景地图，满足各行业领域客户对实景地图的需求，
              </span>
            </div>
          </li>
          <li>
            <span>
              <img src="../../assets/img/德昌信科.jpg" alt="" srcset="" />
            </span>
            <div style="margin-top: 20px">
              <h4>商标注册证</h4>
              <span>
                商标的注册，有利于消费者识别锁定信赖的产品，同时也有利于我司不断提高稳定产品和服务的质量促进行业的高质量发展，为消费者带来更优秀的产品。
              </span>
            </div>
          </li>
          <li>
            <span>
              <img src="../../assets/img/实用新型专利.jpg" alt="" srcset="" />
            </span>
            <div style="margin-top: 20px">
              <h4>实用新型专利证书</h4>
              <span>
                国家知识产权局依照中华人民共和国专利法经过初步筛查，授予我司此专利权。这既是对公司实力的肯定，也是促进公司良好发展的无限动力。
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <collect></collect>
    <records></records>
  </div>
</template>

<script>
import about from "../../components/abouttitle/abouttitle.vue";
import collect from "../../components/collect/collect.vue";
import records from "../../components/records/records.vue";
export default {
  name: "advertisingList",
  data() {
    return {};
  },
  components: {
    collect,
    records,
    about,
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.indent {
  text-indent: 1em;
}
.home {
  img {
    pointer-events: none;
  }
  .understand {
    .text {
      text-align: center;
      margin-top: 30px;
    }
    .understand_title {
      padding: 50px 0px 30px 0px;
      text-align: center;
    }
    .course {
      padding: 5% 7% 2% 7%;
      font-size: 20px;
      text-align: center;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      li {
        width: 120px;
        // background-color: antiquewhite;
        margin-left: 20px;
        text-align: center;

        height: 100px;
        span {
          margin-top: 10px;
        }
        h2 {
          margin-top: 25px;
        }
      }
      #icon1 {
        background-image: url(../../assets//img/about-icon01.png);
        background-repeat: no-repeat;
        background-position: 25px 0px;
        background-size: 102px;
        padding-left: 10px;
      }
      #icon2 {
        background-image: url(../../assets//img/about-icon02.png);
        background-repeat: no-repeat;
        background-position: 20px 0px;
        background-size: 102px;
        padding-left: 10px;
      }
      #icon3 {
        background-image: url(../../assets//img/about-icon03.png);
        background-repeat: no-repeat;
        background-position: 25px 0px;
        background-size: 102px;
        padding-left: 10px;
      }
      #icon4 {
        background-image: url(../../assets//img/about-icon04.png);
        background-repeat: no-repeat;
        background-position: 18px 0px;
        background-size: 102px;
        padding-left: 10px;
      }
      #icon5 {
        background-image: url(../../assets//img/about-icon05.png);
        background-repeat: no-repeat;
        background-position: 10px 0px;
        background-size: 102px;
      }
    }
    .abstract {
      font-size: 16px;
      line-height: 25px;
      padding: 3% 7% 3% 7%;
    }
  }
  .honor {
    background-color: aliceblue;
    text-align: center;
    padding-top: 30px;
    .honor_img {
      font-size: 16px;
      padding: 2% 8% 2% 8%;
      ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
        li {
          width: 48%;
          height: 100%;
          padding: 15px 0 15px 0;
          margin-left: 15px;
          margin-top: 15px;
          box-sizing: border-box;
          background-color: white;
          display: flex;
          span {
            img {
              width: 250px;
              height: 150px;
            }
          }
          div {
            line-height: 30px;
            padding: 10px;
          }
        }
      }
    }
    .patent_img {
      img {
        height: 250px !important;
      }
      span {
        text-align: center;
      }
    }
  }
}
</style>
